import React from 'react'
import styled from 'styled-components';
import { colors, desktopBp, desktopVw, fonts } from '../../../styles';
import { RichText } from '../../sections/RichText/RichText';

const SectionHistoryContainer = ({
    items
}) => {

    return (
        <Container className='container container-xl'>
            <Wrapper>
                {
                    items.map(({ categoryTitle, historyContent }, index) => {
                        return (
                            <History_Item_Container position={index % 2} key={index}>
                                <Category_Title className='item-title'>{categoryTitle}</Category_Title>
                                <Empty className='empty'>&nbsp;</Empty>
                                <Content>
                                    <RichText content={historyContent} />
                                </Content>
                            </History_Item_Container>
                        )
                    })
                }
                <Progress_Bar>&nbsp;</Progress_Bar>
            </Wrapper>
        </Container>
    )
}

const Container = styled.div`
    ${desktopBp}{
        position: relative;
    }
`

const Content = styled.div`
    .rich-text-p{
        font-size: 18px;
        line-height: 24px;
    }
    table {
        .rich-text-p {
            font-size: 14px;
            line-height: 20px;
        }
        tr {
            /* margin-bottom: 40px; */
            td:first-child {
                min-width: 112px;
                max-width: 112px;
                padding-right: 30px;
                ${desktopBp}{
                    font-weight: bold;
                    font-family: ${fonts.bold};
                    p{
                        letter-spacing: 0;
                    }
                }
            }
            ${desktopBp}{
                display: flex;
                flex-direction: column;
            }
        }
    }
    max-width: 535px;
    @media (max-width: 1280px) {
        max-width: ${desktopVw(535)};
    }
    ${desktopBp}{
        max-width: calc(100% - 61px);
    }
    background: transparent linear-gradient(180deg, #000D0D 0%, #272A31 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 20px;
`

const Empty = styled.div`
    width: calc(100% - 535px);
    @media (max-width: 1280px) {
        width: calc(100% - ${desktopVw(535)});
    }
    ${desktopBp}{
        width: 61px;
    }
    height: 100%;
    line-height: 24px;
    border-bottom: 1px dashed #FADE4B;;

`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    padding-top: 95px;
    ${desktopBp}{
        padding-top: 45px;
    }
`

const Category_Title = styled.div`
        position: absolute;
        height: 88px;
        width: 88px;
        top: 0;
        font-family: ${fonts.bold};
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        background-color: #D3A129;
        /* left: 50%; */
        /* right: 0; */
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        text-align: center;
        padding: 0 9px;
        ${desktopBp}{
            font-size: 14px;
            line-height: 19px;
            width: 78px;
            height: 78px;
            padding: 0 8px;
        }
`

const History_Item_Container = styled.div`
    position: relative;
    width: 50%;
    display: flex;
    padding-top: 20px;
    align-self: ${(props) => props.position === 0 ? 'flex-start' : 'flex-end'};
    .item-title{
        left: ${(props) => props.position === 1 ? '0' : 'unset'};
        right: ${(props) => props.position === 0 ? '0' : 'unset'};
        transform: translateX(${(props) => props.position === 1 ? '-50%' : '50%'});
        z-index: 3;
        ${desktopBp}{
            left: 0;
            transform: translateX(12px);
        }
    }

    .empty{
        order: ${(props) => props.position === 0 ? '1' : '0'};
        ${desktopBp}{
            order: 0;
        }
    }
    ${desktopBp}{
        /* width: calc(100% - 55.5px); */
        width: 100%;
        padding-left: 55.5px;
        align-self: flex-end;
        margin-top: 30px;
    }
`

const Progress_Bar = styled.div`
    width: 1px;
    z-index: 2;
    height: 100%;
    top: 0px;
    left: calc(50% - 1px);
    position: absolute;
    border-left: 1px dashed #FADE4B;
    ${desktopBp}{
        left: 55.5px;
    }
`

export default SectionHistoryContainer