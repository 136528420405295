import React from 'react'
import styled from 'styled-components'
import { fonts } from '../../../styles'
import HeadingLevel from '../../shared/HeadingLevel/HeadingLevel'
const SectionTitleContainer = ({
    data
}) => {
    const {
        containerSize,
        subTitle,
        headerLevel,
        title,
        description
    } = data

    return (
        <Wrapper>
            <div className={`container container-${containerSize || 'lg'}`}>
                <Title>
                    <HeadingLevel title={title} headingLevel={headerLevel} />
                </Title>
                {
                    subTitle
                        ? <Sub_Title>
                            {
                                subTitle.split('/n').map((phrase, index) => !phrase
                                    ? (
                                        <span key={index} className="empty-span" style={{ display: 'block' }}>&nbsp;</span>
                                    )
                                    : (
                                        <span key={index} className="content-span" style={{ display: 'block' }}>{phrase}</span>
                                    )
                                )
                            }
                        </Sub_Title>
                        : <></>
                }
                <Description>{description?.description}</Description>
            </div>
        </Wrapper >
    )
}

const Wrapper = styled.div`
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
`

const Title = styled.div`
    
`

const Sub_Title = styled.p`
    font-size: 18px;
    font-family: ${fonts.regular};
    margin-top: 10px;
    .content-span{
        line-height: 24px;
    }
    .empty-span {
        line-height: 30px;
    }
`

const Description = styled.p`
    margin-top: 30px;
    font-size: 14px;
    line-height: 20px;
`

export default SectionTitleContainer