import { graphql } from 'gatsby'
import React from 'react'
import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionDecorative from '../components/sections/SectionDecorative/SectionDecorative';
import SectionBreadcrumb from '../components/sections/SectionBreadcrumb/SectionBreadcrumb';
import SectionTitleContainer from '../components/sections/SectionTitleContainer/SectionTitleContainer';
import SectionHistoryContainer from '../components/History/SectionHistoryContainer/SectionHistoryContainer';

const History = ({
    pageContext,
    location,
    data: {
        contentfulStlPageHistory: {
            slug,
            seoTitle,
            seoDescription,
            sections,
            pageTitle,
            historyItems
        }
    }
}) => {
    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
            />
            <div className='content'>
                {
                    sections.map((section: any, index: number) => {

                        switch (section.__typename) {
                            case "ContentfulComponentBackgroundHero":
                                return (
                                    <HeroComponent data={section} key={index} />
                                )
                            case "ContentfulStlSectionDecorativeItems":
                                return (
                                    <SectionDecorative data={section} key={index} />
                                )
                            case "ContentfulSectionBreadCrumbs":
                                return (
                                    <SectionBreadcrumb data={section} key={index} title={pageTitle} />
                                )
                            case "ContentfulStlSectionTitleContainer":
                                return (
                                    <SectionTitleContainer data={section} key={index} />
                                )
                        }
                    })
                }
                <div>
                    <SectionHistoryContainer items={historyItems} />
                </div>
            </div>
        </div>
    )
}

export const HistoryQuery = graphql`
    query historyQuery($id: String!){
        contentfulStlPageHistory (id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            pageTitle
            sections {
                __typename
                ...componentBackgroundHero
                ...sectionTitleContainer
                ...sectionBreadcrumb
            }
            historyItems {
                ... on ContentfulStlHistoryItem {
                    categoryTitle
                    historyContent {
                        raw
                    }
                }
            }
        }
    }
`

export default History