import React from 'react'
import styled from 'styled-components';
import { colors, fonts } from '../../../styles';
import HeaderLink from '../../Header/HeaderLink/HeaderLink';

const SectionBreadcrumb = ({
    data,
    title
}) => {
    const {
        links
    } = data
    return (
        <Wrapper className='section-breadcrumb'>
            <div className='container container-xl'>
                <Container>
                    {
                        links.map((item, index) => (
                            <Item key={index}>
                                <HeaderLink data={item} />
                            </Item>
                        ))
                    }
                    <Current_Page>{title}</Current_Page>
                </Container>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 15px 0;
    border-bottom: 0.5px solid #646464;
`
const Item = styled.div`
    a,p{
        line-height: 14px;
        font-size: 12px;
        color: ${colors.gold};
        font-weight: 400;
        font-family: ${fonts.regular};

        &::after{
            content: "|";
            margin: 0 10px;
            color: #2d2d2d;
        }
    }
`

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Current_Page = styled.div`
    line-height: 14px;
    font-size: 12px;
    color: ${colors.white};
`
export default SectionBreadcrumb